import Hero from "./Hero"
import Header from "./Header"
import Section from "./Section"
import Testimonial from "./Testimonial"
import ContactUs from "./ContactUs"
import Footer from "./Footer"
import AboutUs from "./Benefits"
import Pricing from "./Pricing"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function LandingPage() {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Hero />
      <Section />
      <AboutUs />
      <Testimonial />
      <Pricing />
      <ContactUs />
      <Footer />
      </ThemeProvider>
    </>
  );
}

export default LandingPage;
