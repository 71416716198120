import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { loadStripe } from '@stripe/stripe-js'

const tiers = [
  {
    title: 'Basic',
    price: '99',
    description: [
      '- Free trial - 2 weeks',
      '- Free onboarding calls',
      '- Marketing specialist',
      '- Facebook and Instagram',
      '- Up to 3 postings per week',
    ],
    buttonText: 'Get started for free',
    buttonVariant: 'outlined',
    stripeItem: {
      price: "price_1NIZRYBVPLomIMUnZApdxLW1",
      quantity: 1
    }
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '119',
    description: [
      '- Includes all of basic plan',
      '- Client assessments',
      '- Engagement analytics',
      '- Quarterly reviews',
      '- Twitter',
      '- Up to 10 posts per week'
        ],
    buttonText: 'Get started for free',
    buttonVariant: 'contained',
    stripeItem: {
      price: "price_1NIZndBVPLomIMUnthe1fUze",
      quantity: 1
    }
  },
  {
    title: 'Enterprise',
    price: '159',
    description: [
      '- Includes all of Pro plan',
      '- Client need assessment',
      '- Personalised market plan',
      '- Conversion Specialist',
      '- Unlimited postings'
    ],
    buttonText: 'Get started for free',
    buttonVariant: 'outlined',
    stripeItem: {
      price: "price_1NIZr6BVPLomIMUnDjJiJfhH",
      quantity: 1
    }
  },
];

function PricingContent() {
  // async function checkout(stripeItem) {
    const checkout = async (stripeItem) => {
      // console.log("hello")
      const stripe = await loadStripe('pk_test_51NHBMOBVPLomIMUnG26dcTV2YD9207EwyLJDitrsFxB4OLhcyyKpdFIZ4FO9FYs8TpVZDq0CkUhtr62kcAvnCdAD00qxglPUZc')
      const { error } = await stripe.redirectToCheckout({
        lineItems: [stripeItem],
        mode: 'subscription',
        successUrl: 'https://www.unrealPosts.com/SignIn',
        cancelUrl: 'https://www.unrealPosts.com/#Pricing'
      })
      console.log("Error in PricingContent()", error.message)
    }
  
  return (
    <React.Fragment>
      <section id="Pricing"/>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Bring in more customers, save time and save money. Try it free for 2 weeks. Cancel anytime at no cost during or after the trial period, no question asked!
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="left"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button 
                  fullWidth variant={tier.buttonVariant}
                  // component={Link} to="/Register"
                  onClick={() => checkout(tier.stripeItem)}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}