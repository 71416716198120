import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  TextareaAutosize,
  Button,
} from '@mui/material';
import useStyles from '../styles/styles';
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const [successOpen, setSuccessOpen] = useState(false);
  const [validEmailOpen, setValidEmailOpen] = useState(false);
  const [validFormOpen, setValidFormOpen] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!email.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")){
      setValidEmailOpen(true)
      return
    }
    if (firstName.length < 1 || email.length < 1 || subject.length < 1 || message.length < 1) {
      setValidFormOpen(true)
      return
    }
    let templateParams = {to_name:"Unreal", from_user_subject: subject, from_user_name: firstName, from_user_email: email, from_user_message: message}
    emailjs.send('service_6yssu4l', 'template_7irm4yt', templateParams, 'vXD0lGF3qBRvymnDw')
      .then((result) => {
          console.log(result.text);
          setSuccessOpen(true);
      }, (error) => {
          console.log(error.text);
      });
  };
  
  return (
    <Box className={classes.formContainer}>
      <section id="ContactUs"/>
      <Typography variant="h2" className={classes.formHeading}>
        Contact Us
      </Typography>
      <Box
        className={classes.form}
        component="form"
        noValidate
        autoComplete="on"
      >
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <TextareaAutosize
          aria-label="minimum height"
          minRows={6}
          placeholder="Enter a message"
          className={classes.textArea}
          spellCheck
          value={form.message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ width: '200px', fontSize: '16px' }}
          onClick={sendEmail}
        >
          Submit
        </Button>
        <Collapse in={successOpen}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Message Sent Successfully - Expect a response within the next two business day!
        </Alert>
      </Collapse>
        <Collapse in={validEmailOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setValidEmailOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Message failed - The provided email is invalid
        </Alert>
      </Collapse>
        <Collapse in={validFormOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setValidFormOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Message failed - Every field must be completed
        </Alert>
      </Collapse>
      </Box>
    </Box>
  );
};

export default ContactUs;