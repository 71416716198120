import { Box, Typography } from '@mui/material';
import Header from "./Header"
import Footer from "./Footer"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },});

const Features = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 20}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Discover Our Features
      </Typography>
      <Typography variant="body1" component="p">
      Uncover the full potential of your social media presence with our suite of cutting-edge features. Designed with ease, growth, and ingenuity in mind, our tools are crafted to help businesses like yours connect, engage, and convert like never before. </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:10}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Personalized and Edited Posts
      </Typography>
      <Typography variant="body1" component="p">
      We understand that every brand is unique, and we believe your social media presence should reflect that. Our tool allows for full personalization of your posts schceduled over one month ahead of time, ensuring each one captures your brand’s personality and resonates with your audience. In case anything changes and for full ability to control the content, we also provide a comprehensive editing feature, allowing you to fine-tune the content to your preference. </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Multi-Platform Reach
      </Typography>
      <Typography variant="body1" component="p">
      Your customers are everywhere, and we believe your brand should be, too. Our tool seamlessly integrates with the most popular social media platforms – Facebook, Instagram, and Twitter. With our streamlined posting capabilities, you can reach your audience wherever they are, ensuring a consistent brand presence across all platforms. Finally, our tool provides in-depth analytics that paint a clear picture of your social media performance. Understand which posts resonate with your audience, when to post for maximum engagement, and how to adjust your strategy based on data-driven insights.
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Conversion Optimization
      </Typography>
      <Typography variant="body1" component="p">
      Our tool doesn't just help you reach your audience – it helps you convert them. Leveraging advanced algorithms and strategic insights, we ensure your posts not only gain impressions but also drive action. Our conversion optimization feature enables you to transform your social media followers into loyal customers and your impressions into tangible bookings.
</Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Try Our Features Today
      </Typography>
      <Typography variant="body1" component="p">
      Experience how our innovative features can revolutionize your social media strategy. Start personalizing, editing, reaching, converting, and growing with us today. Ready to take your social media game to the next level? Connect with us now to get started. Together, we'll turn impressions into impact, followers into customers, and posts into profit.
 </Typography>
    </Box>
    </div>
    <Footer/>
      </ThemeProvider>
    </>
  );
};

export default Features;