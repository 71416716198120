import { Box, Typography } from '@mui/material';
import Header from "./Header"
import Footer from "./Footer"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },});

const Legal = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 20}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Our Legal Commitments
      </Typography>
      <Typography variant="body1" component="p">
      Our firm recognize the importance of transparency and trust, especially when it comes to legal matters. We aim to create an environment that safeguards your interests while providing you with the highest level of service.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Data Protection
      </Typography>
      <Typography variant="body1" component="p">
      Your data security is our priority. We employ stringent measures to ensure your data's safety and privacy. We comply with all applicable data protection regulations and continually review and update our security practices to stay ahead of emerging threats.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Copyright & Intellectual Property
      </Typography>
      <Typography variant="body1" component="p">
      Copyrights and intellectual property are of utmost importance. We guarantee every post is authored in house to ascertain their autheticity and uniqueness. Content provided by clients should not infringe on the rights of others and must have the appropriate permissions.      
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:10}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Disclaimers
      </Typography>
      <Typography variant="body1" component="p">
      Please note that while we strive to maximize your social media success, our services are not a guarantee of specific outcomes. The nature of social media, marketing, and the online space is dynamic and unpredictable. Therefore, results can vary depending on numerous factors, many of which are beyond our control.
      Our services also do not constitute legal or financial advice. Our legal terms and policies may be updated periodically to reflect changes in our services or in response to evolving legal requirements. We encourage you to review this section regularly to stay informed.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Here to Help
      </Typography>
      <Typography variant="body1" component="p">
      Our firm is committed to providing you with reliable, trustworthy services and clear, understandable legal information. If you have any questions or need clarification regarding our legal terms, please do not hesitate to contact us. Our goal is to ensure you feel confident and supported in every aspect of our partnership.

Remember, while our primary role is to assist with your social media management, we're here to help with much more than that. We're a part of your team, committed to your success and peace of mind in all areas of our collaboration.      </Typography>
    </Box>
    </div>
    <Footer/>
      </ThemeProvider>
    </>
  );
};

export default Legal;