import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import myteam from '../images/media1.png';
import useStyles from '../styles/styles';
import { loadStripe } from '@stripe/stripe-js'


const Hero = () => {
  const classes = useStyles();

  const checkout = async (stripeItem) => {
    const stripe = await loadStripe('pk_test_51NHBMOBVPLomIMUnG26dcTV2YD9207EwyLJDitrsFxB4OLhcyyKpdFIZ4FO9FYs8TpVZDq0CkUhtr62kcAvnCdAD00qxglPUZc')
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: 'price_1NIZndBVPLomIMUnthe1fUze',
        quantity: 1
      }],
      mode: 'subscription',
      successUrl: 'https://www.unrealPosts.com/SignIn',
      cancelUrl: 'https://www.unrealPosts.com/#Pricing'
    })
    console.log("Error in PricingContent()", error.message)
  }

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Convert Your Online Presence Into Bookings
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            Build community, reach new customers and convert more bookings with unreal posts! Try us for 2 weeks at no cost to you!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' }}
            // component={Link} to="#Pricing"
            // href={"/#Pricing"}
            onClick={() => checkout({
              price: "",
              quantity: 1
            })
          }
          >
            sign up now
          </Button>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={myteam} alt="My Team" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;