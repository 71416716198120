import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import bestTeams from '../images/media2.png';
import useStyles from '../styles/styles';
import { loadStripe } from '@stripe/stripe-js'

const Benefits = () => {
  const classes = useStyles();

  const checkout = async (stripeItem) => {
    // console.log("hello")
    const stripe = await loadStripe('pk_test_51NHBMOBVPLomIMUnG26dcTV2YD9207EwyLJDitrsFxB4OLhcyyKpdFIZ4FO9FYs8TpVZDq0CkUhtr62kcAvnCdAD00qxglPUZc')
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: 'price_1NIZndBVPLomIMUnthe1fUze',
        quantity: 1
      }],
      mode: 'subscription',
      successUrl: 'https://www.unrealPosts.com/SignIn',
      cancelUrl: 'https://www.unrealPosts.com/#Pricing'
    })
    console.log("Error in PricingContent()", error.message)
  }

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={5}>
          <img src={bestTeams} alt="My Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Generate presence AND bookings
          </Typography>
          <Typography className={classes.aboutUsSubtitle}>
            New customers find out they were paying far too much and getting far too little by relying on employees to be their social media experts. Let your staff focus on their strength and let us deliver presence that converts into more bookings.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' }}
            // component={Link} to="/Pricing"
            // href={"/#Pricing"}
            onClick={() => checkout({
              price: "",
              quantity: 1
            })
          }
          >
            Sign up now
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Benefits;