import LandingPage from "./components/LandingPage"
import SignIn from "./components/SignIn"
import Dashboard from "./components/Dashboard"
import AboutUs from "./components/AboutUs"
import Features from "./components/Features"
import Resources from "./components/Resources"
import Legal from "./components/Legal"
import { Routes, Route} from "react-router-dom"

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage/>}/>
      <Route path="/SignIn" element={<SignIn/>}/>
      <Route path="/Dashboard" element={<Dashboard/>}/>
      <Route path="/AboutUs" element={<AboutUs/>}/>
      <Route path="/Features" element={<Features/>}/>
      <Route path="/Resources" element={<Resources/>}/>
      <Route path="/Legal" element={<Legal/>}/>
    </Routes>
  );
}

export default App;
