import { Box, Typography } from '@mui/material';
import Header from "./Header"
import Footer from "./Footer"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },});

const Resources = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 20}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Explore Our Resources
      </Typography>
      <Typography variant="body1" component="p">
      Welcome to our trove of resources, where we offer an abundance of support and tools designed to optimize your social media success. Our resourcefulness and desire to assist are mirrored in each facet of our service, enabling your business to thrive in the bustling realm of social media.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:10}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Onboarding Calls
      </Typography>
      <Typography variant="body1" component="p">
      Every journey begins with a single step, and ours begins with a comprehensive onboarding call. During this call, we’ll introduce you to our services, our team, and the tools you'll be using. We'll walk you through the ins and outs of our system and how best to leverage it for your specific needs. Consider this our official welcome to a world of less stress and more social media success.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Ongoing Customer Support
      </Typography>
      <Typography variant="body1" component="p">
      We believe that our clients' success is our success, and we demonstrate this belief through relentless support. Our dedicated customer support personnel are always at your service, ready to answer any queries, resolve any issues, and provide the data analytics you need. This ongoing support ensures you're never alone in your social media journey.      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Custom Marketing Services
      </Typography>
      <Typography variant="body1" component="p">
      Every business is unique, and sometimes, a more bespoke marketing solution is needed. To cater to this, we offer custom marketing services in partnership with SEO, website, and brand specialists. Whether you need to optimize your website, improve your search engine ranking, or refine your branding, our partners are ready to assist.</Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Your Growth, Our Commitment
      </Typography>
      <Typography variant="body1" component="p">
      Our resources are your resources. With us, you have access to an abundance of tools and support designed to fuel your business's growth. We're more than just a service provider; we're a partner who shares your goals and is committed to helping you achieve them. Experience the ease, abundance, and resourcefulness we offer and watch your business thrive on social media. Connect with us today to tap into these resources and start transforming your social media presence. </Typography>
    </Box>
    </div>
    <Footer/>
      </ThemeProvider>
    </>
  );
};

export default Resources;