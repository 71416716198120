import { Box, Typography } from '@mui/material';
import Header from "./Header"
import Footer from "./Footer"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
    },});

const AboutUs = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 20}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Meet Our Team
      </Typography>
      <Typography variant="body1" component="p">
      Welcome to the core of our operation – our dedicated team.
      We are a collective of seasoned marketers, innovative strategists, social media maestros, 
      and content creators with a shared mission: 
      to help businesses like yours reclaim their time and redefine their online presence.
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:10}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Trusted Advisors
      </Typography>
      <Typography variant="body1" component="p">
      Our team members are more than social media experts – they are trusted advisors who prioritize your business's unique needs. 
      We understand the significance of trust in our line of work, and we do everything possible to uphold it. 
      Our goal is to become a dependable extension of your team, one that you can rely on for all your social media needs.
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Committed Professionals
      </Typography>
      <Typography variant="body1" component="p">
      We are committed to your success. From the moment we take on a project, your business goals become our goals. 
      We work tirelessly to ensure your social media strategy is not only effective, but also aligned with your brand's identity and vision. 
      We don’t believe in a one-size-fits-all approach. 
      Instead, we craft personalized strategies that resonate with your target audience and foster engagement.
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Action-Oriented
      </Typography>
      <Typography variant="body1" component="p">
      Our team thrives on action. 
      We know that in the dynamic world of social media, timing is everything. 
      That’s why we’re always ready to react, respond, and post at the right moment. 
      We proactively monitor trends and adapt our strategies accordingly, ensuring your brand remains at the forefront of your industry.
      </Typography>
    </Box>
    <Box sx={{ width: '50%', margin: '0 auto' , marginTop: 10, marginBottom:0}}>
      <Typography
        variant="h4"
        component="h4"
        sx={{ mb: '2rem' }}
      >
        Join Us
      </Typography>
      <Typography variant="body1" component="p">
      We invite you to delegate your social media posting to us. 
      Let us handle the complexities of planning, creating, scheduling, and analyzing your social media content. 
      With us by your side, you can focus on what you do best – running your business.
      </Typography>
    </Box>
    </div>
    <Footer/>
      </ThemeProvider>
    </>
  );
};

export default AboutUs;