import React from 'react';
import { Typography, Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Unreal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => {
  
  const footers = [
  {
    title: 'Company',
    description: ['Team', 'Committment'],
    location:'./AboutUs'
  },
  {
    title: 'Features',
    description: ['Features','Cool stuff'],
    location:'./Features'
  },
  {
    title: 'Resources',
    description: ['Resources','Support'],
      location:'./Resources'

  },

  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
    location:'./Legal'

  },
];

  return (
    <React.Fragment>
    <section id="Footer"/>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href={footer.location} underline="none" key={item.id}>

                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment>
  );
};

export default Footer;